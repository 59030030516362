<template>
  <div class="100vh mt-15">
    <v-container bg fill-height grid-list-md text-xs-center>
      <v-layout row wrap align-center>
        <v-flex>
          <v-img
            class="mb-16 mx-auto"
            width="185px"
            height="60px"
            src="@/assets/logo/facenet.png"
          ></v-img>
          <v-card rounded="md" flat class="wd-486 ma-auto pa-4">
            <v-card-title
              class="font-24 fw-600 greyColor--text mb-3 justify-center"
              >Verify Your Email</v-card-title
            >
            <v-card-text>
              <p class="text-center">
                An email has been sent to <b>{{ email }}</b> with a link to verify your
                account. If you do not have received the email, Please check
                your spam box or contact support team.
              </p>
            </v-card-text>
            <back-to-login />
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";

export default {
  components: { BackToLogin },
  data() {
    return {
      email: "",
    };
  },
  // Getting provided email for resetting password from local storage
  mounted() {
    this.email = localStorage.getItem("forgot-email");
  },
  // Setting provided email empty in local storage on page destroying
  beforeDestroy() {
    this.email = localStorage.setItem("forgot-email", "");
  },
};
</script>

<style></style>
